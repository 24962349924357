
<template>
  <div class="wrapper1">
    <stuff-nav
      @add="handleAddItem"
      @change="handleTypeChange"
      @search="handleSearch"
    ></stuff-nav>
    <list :list="stuffList" @edit="handleEditItem" @succ="handleSucc"></list>
    <edit-stuff
      :show="show"
      :type="type"
      :item="item"
      :stuffTypeList="stuffTypeList"
      @succ="handleSucc"
    ></edit-stuff>
  </div>
</template>

<script>
import List from "./components/List";
import StuffNav from "./components/Nav";
import EditStuff from "./components/EditStuff";
export default {
  name: "Stuff",
  components: { StuffNav, List, EditStuff },
  data() {
    return {
      show: false,
      type: "add",
      item: {},
      stuffList: [],
      stuffTypeList: [],
    };
  },
  watch: {},
  computed: {},
  async created() {
    this.getAllStuff();
    this.getAllStuffType();
  },
  mounted() {},
  methods: {
    //获取材质列表
    async getAllStuff() {
      this.stuffList = await this.$store.dispatch("getAllStuff", true);
      this.allStuff = this.stuffList;
    },
    //获取材质型号列表
    async getAllStuffType() {
      const res = await this.$store.dispatch("getAllStuffType");
      this.stuffTypeList = res.categories;
    },
    //改变筛选
    handleTypeChange(types) {
      if (types.indexOf("all") > -1) {
        this.stuffList = this.allStuff;
        return;
      }
      this.stuffList = this.allStuff.reduce((prev, cur) => {
        if (types.findIndex((item) => item === cur.category_id) > -1) {
          prev.push(cur);
        }
        return prev;
      }, []);
    },
    //搜索
    handleSearch(val) {
      if (val === "") {
        this.stuffList = this.allStuff;
        return;
      }
      this.stuffList = this.stuffList.reduce((prev, cur) => {
        if (cur.name.indexOf(val) > -1) {
          prev.push(cur);
        }
        return prev;
      }, []);
    },
    //提交成功
    handleSucc() {
      this.getAllStuff();
    },
    //点击弹出新增弹窗
    handleAddItem() {
      this.show = !this.show;
      this.type = "add";
    },
    //点击弹出编辑弹窗
    handleEditItem(item) {
      this.item = item;
      this.show = !this.show;
      this.type = "edit";
    },
  },
};
</script>
<style lang="less" scoped>
.wrapper1 {
  .el-row {
    display: flex;
    align-items: center;
    .el-dropdown {
      height: 30px;
      line-height: 30px;
      text-align: center;
      width: 100px;
      border: 1px solid #ccc;
      cursor: pointer;
    }
  }
  .el-table {
    margin-top: 20px;
    .red {
      color: #ff0000;
    }
  }
}
</style>
