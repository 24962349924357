<template>
  <el-row :gutter="20">
    <el-col :span="3">
      <all-select
        :model="stuffTypes"
        :all="allStuffType"
        placeholder="材质分类选择"
        @change="handleChangeSelect"
      >
        <el-option
          v-for="item in stuffTypeList"
          :key="item.id"
          :label="item.name"
          :value="item.id"
        >
        </el-option>
      </all-select>
    </el-col>
    <el-col :span="4">
      <el-input
        placeholder="请输入材质关键字"
        v-model="searchVal"
        class="input-with-select"
      >
        <el-button
          slot="append"
          icon="el-icon-search"
          @click="handleSearch"
        ></el-button>
      </el-input>
    </el-col>
    <el-col :span="3">
      <el-button type="primary" @click="handleAddItem" icon="el-icon-plus"
        >新增材质</el-button
      >
    </el-col>
  </el-row>
</template>
<script>
import AllSelect from "@/components/AllSelect";
export default {
  name: "Nav",
  components: { AllSelect },
  data() {
    return {
      searchVal: "",
      stuffTypeList: [],
      stuffTypes: [],
      allStuffType: [],
    };
  },
  created() {
    this.getAllStuffType();
  },
  methods: {
    //获取材质分类
    async getAllStuffType() {
      const res = await this.$store.dispatch("getAllStuffType");
      this.stuffTypeList = res.categories;
      this.stuffTypes = ["all"];
      this.allStuffType = this.stuffTypeList.map((item) => {
        return item.id;
      });
    },
    //新增
    handleAddItem() {
      this.$emit("add");
    },
    //搜索
    handleSearch() {
      this.$emit("search", this.searchVal);
    },
    //改变类型选择
    handleChangeSelect(stuffTypes) {
      this.stuffTypes = stuffTypes;
      this.$emit("change", stuffTypes);
    },
  },
};
</script>
<style lang="less" scoped>
</style>