<template>
  <table-container :list="list" height="750">
    <el-table-column type="index" label="序号"> </el-table-column>
    <el-table-column prop="name" label="材质名称"> </el-table-column>
    <el-table-column prop="mcode" label="商家编码"> </el-table-column>
    <el-table-column
      prop="category_name"
      label="材质分类"
      :filters="stuffTypeList"
      :filter-method="handleFilterStuffType"
      filter-placement="bottom-end"
    >
    </el-table-column>
    <el-table-column label="宽高">
      <template slot-scope="scope">
        <span>{{ scope.row.width }}/{{ scope.row.height }}</span>
      </template>
    </el-table-column>
    <el-table-column prop="img_url" label="图片">
      <template slot-scope="scope">
        <ky-image
          :images="[scope.row.img_url ? base_url + scope.row.img_url : '']"
          :src="scope.row.img_url ? base_url + scope.row.img_url : ''"
        ></ky-image>
      </template>
    </el-table-column>
    <el-table-column prop="created_at" label="创建日期"> </el-table-column>
    <el-table-column label="操作">
      <template slot-scope="scope">
        <el-button type="primary" @click="handleEdit(scope.row)"
          >编辑</el-button
        >
        <delete-btn :item="scope.row" @del="handleDelete"></delete-btn>
      </template>
    </el-table-column>
  </table-container>
</template>
<script>
import KyImage from "@/components/KyImage";
export default {
  name: "List",
  components: { KyImage },
  props: {
    list: Array,
  },
  data() {
    return {
      stuffTypeList: [],
    };
  },
  created() {
    this.getAllStuffType();
  },
  methods: {
    async getAllStuffType() {
      const res = await this.$store.dispatch("getAllStuffType");
      this.stuffTypeList = res.categories.map((item) => {
        return {
          value: item.id,
          text: item.name,
        };
      });
    },
    handleEdit(item) {
      this.$emit("edit", item);
    },
    async handleDelete(item) {
      const res = await this.$store.dispatch("delPhoneStuff", {
        stuff_id: item.id,
      });
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
      });
    },
    handleFilterStuffType(value, row) {
      return value === row.category_id;
    },
  },
};
</script>
<style lang="less" scoped>
</style>