<template>
  <ky-dialog :title="title" :show="show" :hide="hide">
    <el-form
      slot="dialog-content"
      :label-position="labelPosition"
      label-width="90px"
      :model="fromData"
      :rules="rules"
      ref="fromData"
    >
      <el-form-item label="材质分类:" prop="category_id">
        <el-select
          filterable
          v-model="fromData.category_id"
          placeholder="请选择"
          style="display: block"
        >
          <el-option
            v-for="item in stuffTypeList"
            :key="item.id"
            :label="item.name"
            :value="item.id"
          >
          </el-option>
        </el-select>
      </el-form-item>
      <el-form-item label="材质名:" prop="name">
        <el-input v-model="fromData.name"></el-input>
      </el-form-item>
      <el-form-item label="简码:" prop="brief_code">
        <el-input v-model.trim="fromData.brief_code"></el-input>
      </el-form-item>
      <el-form-item label="排序:" prop="sort">
        <el-input v-model.number="fromData.sort"></el-input>
      </el-form-item>
      <el-form-item label="商家编码:" prop="mcode">
        <el-input v-model.trim="fromData.mcode"></el-input>
      </el-form-item>
      <el-form-item label="材质宽度:" prop="width">
        <el-input v-model.number="fromData.width"></el-input>
      </el-form-item>
      <el-form-item label="材质高度:" prop="height">
        <el-input v-model.number="fromData.height"></el-input>
      </el-form-item>
      <el-form-item label="材质图片:">
        <upload-image :src="[imageSrc]" @getImage="handleGetImage"></upload-image>
      </el-form-item>
    </el-form>
    <span slot="dialog-footer" class="dialog-footer">
      <el-button @click="handleCancel">取 消</el-button>
      <el-button type="primary" @click="handleComfirm">确 定</el-button>
    </span>
  </ky-dialog>
</template>
<script>
import UploadImage from "@/components/UploadImage";
import rules from "@/util/rules";
export default {
  name: "EditStuff",
  components: {
    UploadImage,
  },
  props: {
    type: String,
    show: Boolean,
    item: Object,
    stuffTypeList: Array,
  },
  data() {
    return {
      hide: false,
      title: "新增型号",
      labelPosition: "right",
      imageSrc: "",
      fromData: {
        category_id: "",
        name: "",
        mcode: "",
        brief_code: "",
        sort: 1,
        image: "",
        width: "",
        height: "",
      },
      rules: {
        name: [rules.noNullBlur("请输入材质名称")],
        category_id: [rules.noNullBlur("类型不能为空")],
        mcode: [rules.noNullBlur("商家编码不能为空")],
        brief_code: [rules.noNullBlur("材质简码不能为空")],
        sort: [rules.numberBlur()],
        width: [rules.numberBlur()],
        height: [rules.numberBlur()],
      },
    };
  },
  watch: {
    show(e) {
      if (this.type === "add") {
        this.title = "新增型号";
        this.fromData = {
          id: "",
          category_id: "",
          name: "",
          mcode: "",
          brief_code: "",
          sort: 1,
          image: "",
          width: "",
          height: "",
        };
        this.imageSrc = "";
      } else {
        this.title = "编辑型号";
        this.imageSrc = this.base_url + this.item.img_url;
        this.fromData = {
          id: this.item.id,
          category_id: this.item.category_id,
          name: this.item.name,
          mcode: this.item.mcode,
          brief_code: this.item.brief_code,
          sort: this.item.sort,
          image: "",
          width: this.item.width,
          height: this.item.height,
        };
      }
    },
  },
  methods: {
    //上传图片
    async handleGetImage(fileList) {
      this.fromData.image = fileList[0];
      this.imageSrc = await this.$tool.fileToBase64(fileList[0]);
    },
    //取消
    handleCancel() {
      this.hide = !this.hide;
    },
    //确定
    handleComfirm() {
      let fromData = new FormData();
      fromData.append("name", this.fromData.name);
      fromData.append("brief_code", this.fromData.brief_code);
      fromData.append("category_id", this.fromData.category_id);
      fromData.append("sort", this.fromData.sort);
      fromData.append("mcode", this.fromData.mcode);
      fromData.append("image", this.fromData.image);
      fromData.append("width", this.fromData.width);
      fromData.append("height", this.fromData.height);
      if (this.type === "add") {
        this.handleAddComfirm(fromData);
        return;
      } else {
        fromData.append("id", this.fromData.id);
        this.handleEditComfirm(fromData);
      }
    },
    //新增提交
    async handleAddComfirm(fromData) {
      const res = await this.$store.dispatch("addStuff", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
    //编辑提交
    async handleEditComfirm(fromData) {
      const res = await this.$store.dispatch("editStuff", fromData);
      this.$tool.comfirmToast(res).then(() => {
        this.$emit("succ");
        this.hide = !this.hide;
      });
    },
  },
};
</script>
<style lang="less" scoped>
.dialog-footer {
  display: flex;
  justify-content: flex-end;
}
</style>